import { useLazyQuery, useMutation } from "@apollo/client";
import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { PushNotifications } from "@capacitor/push-notifications";
import type { ActionPerformed, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import { StatusBar, Style } from "@capacitor/status-bar";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { SafeArea } from "capacitor-plugin-safe-area";
import { createContext, useContext, useEffect, useRef, useState } from "react";



import type { DeepPartial, UseDisclosureReturn } from "@chakra-ui/react";
import { useBreakpointValue, useDisclosure } from "@chakra-ui/react";



import { useNavigate, useParams } from "@remix-run/react";



import addBannerViewMutation from "~/api/mutation/addBannerViewMutation";
import addEventViewMutation from "~/api/mutation/addEventViewMutation";
import addPostViewMutation from "~/api/mutation/addPostViewMutation";
import DistrictsQuery from "~/api/query/Districts";
import GetCriteriaSettingsQuery from "~/api/query/GetCriteriaSettingsQuery";
import getConfig from "~/libs/config";
import { generateId } from "~/utils/commons";
import { getClientSideApollo } from "~/utils/graphql";
import { removeFloatingAd, triggerMWPopover } from "~/utils/hotmob";
import type { CriteriaSetting_criteria__region_Input, District_region_Input, LocaleInputType, ModelTypes } from "~/zeus";


/** Provider */
type GlobalContext = {
  setting?: ModelTypes["CriteriaSetting"];
  district?: ModelTypes["District"];
  apolloClient: ApolloClient<NormalizedCacheObject>;
  mobileDrawerDisclosure: UseDisclosureReturn;
  mobileSearchDrawerDisclosure: UseDisclosureReturn;
  mobilePostDrawerDisclosure: UseDisclosureReturn;
  isMobile: boolean | undefined;
  isDesktop: boolean | undefined;
  APP_INSETS?: { top: number; bottom: number };
  DESKTOP_HEADER_HEIGHT: number;
  MOBILE_HEADER_HEIGHT: number;
  GRAPHQL_CLIENT_URL?: string;
  PUSH_TOKEN?: string;
  UDID?: string;
  addEventViewCount: (id: string, type: string) => void;
  addBannerViewCount: (id: string, type: string) => void;
  addPostViewCount: (id: string, type: string) => void;
};

export const globalContext = createContext<GlobalContext | undefined>(
  undefined
);

export const GlobalContextProvider = globalContext.Provider;

export const useGlobalContext = () => {
  return useContext(globalContext) as GlobalContext;
};

export const useGlobalContextInit = (
  defaultValue: DeepPartial<GlobalContext>
): GlobalContext => {
  const apolloClient = useRef<ApolloClient<NormalizedCacheObject>>(
    getClientSideApollo()
  ).current;

  const mobileDrawerDisclosure = useDisclosure({ defaultIsOpen: false });
  const mobileSearchDrawerDisclosure = useDisclosure({ defaultIsOpen: false });
  const mobilePostDrawerDisclosure = useDisclosure({ defaultIsOpen: false });

  const isNative = Capacitor.isNativePlatform();
  const platform = Capacitor.getPlatform();

  const navigate = useNavigate();
  const { region, locale } = useParams();

  const [pushToken, setPushToken] = useState<string>();
  const [deviceUdid, setDeviceUdid] = useState<string>();
  const [appInsets, setAppInsets] = useState({ top: 0, bottom: 0 });
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  const getDeviceUdid = async () => {
    const deviceId = await Device.getId();
    setDeviceUdid(deviceId.identifier);
  };

  const [getDistricts, { data: getDistrictsData }] = useLazyQuery(
    DistrictsQuery,
    {
      client: apolloClient,
      variables: {
        where: {
          region: { equals: region as District_region_Input },
          level: { equals: 1 },
        },
        locale: locale as LocaleInputType,
      },
    }
  );

  const [getCriteriaSettings, { data: getCriteriaSettingsData }] = useLazyQuery(
    GetCriteriaSettingsQuery,
    {
      client: apolloClient,
      variables: {
        where: {
          criteria__region: {
            equals: region as CriteriaSetting_criteria__region_Input,
          },
        },
        locale: locale as LocaleInputType,
      },
    }
  );

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  useEffect(() => {
    getCriteriaSettings();
  }, [getCriteriaSettings]);

  const responsive = useBreakpointValue<"mobile" | "desktop" | "tablet">({
    base: "mobile",
    sm: "mobile",
    md: "mobile",
    lg: "desktop",
    xl: "desktop",
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(responsive === "mobile");
      setIsDesktop(responsive === "desktop");
    }
  }, [responsive]);

  const DESKTOP_HEADER_HEIGHT = 144;
  const MOBILE_HEADER_HEIGHT = 54;

  let APP_INSETS = appInsets;

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };

  const [addEventView] = useMutation(addEventViewMutation, {
    client: apolloClient,
  });

  const [addBannerView] = useMutation(addBannerViewMutation, {
    client: apolloClient,
  });

  const [addPostView] = useMutation(addPostViewMutation, {
    client: apolloClient,
  });

  useEffect(() => {
    const facebookClientId = getConfig().REMIX_PUBLIC_FACEBOOK_CLIENT_ID;
    const googleClientId = getConfig().REMIX_PUBLIC_GOOGLE_CLIENT_ID;

    if (facebookClientId) {
      FacebookLogin.initialize({ appId: facebookClientId });
    }

    if (googleClientId) {
      GoogleAuth.initialize({
        clientId: googleClientId,
        scopes: ["email"],
      });
    }
  }, []);

  useEffect(() => {
    if (isNative) {
      setStatusBarStyleLight();

      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setAppInsets(insets);
      });

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      const checkPermissions = async () => {
        // Register with Apple / Google to receive push via APNS/FCM
        const result = await PushNotifications.checkPermissions();
        if (result.receive === "granted") return PushNotifications.register();

        const permissionResult = await PushNotifications.requestPermissions();
        if (permissionResult.receive === "granted")
          return PushNotifications.register();

        // failed to get permission
        // Show some error
      };

      checkPermissions();

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token: Token) => {
        setPushToken(token.value);
      }).catch((err) => {
        console.log("error", err);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        alert("Error on registration: " + JSON.stringify(error));
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          // console.log("pushNotificationReceived", notification);
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        ({ notification: { data } }: ActionPerformed) => {
          const url = data?.url as string;
          if (!url) return;

          // const isTimableUrl =
          //   url.startsWith("https://timable.com") ||
          //   url.startsWith("https://uat2.timable.com");

          const origin = window.location.origin;
          const isOrigin = url.startsWith(origin);

          const locale = window.location.pathname.match(/\/(zh|en|cn)/)?.[1];

          if (isOrigin) {
            navigate(
              url.replace(origin, "").replace(/\/(zh|en|cn)/, `/${locale}`)
            );
          } else {
            window.open(url, "_blank");
          }
        }
      );

      getDeviceUdid();

      App.addListener("appStateChange", ({ isActive }) => {
        if (isActive) {
          triggerMWPopover(platform);
        } else {
          removeFloatingAd();
        }
      });
    }
  }, [isNative, navigate, platform]);

  const addEventViewCount = (id: string, type: string) => {
    // const OLD_ID_PREFIX = "00000000000000000";
    // const updateId = id?.length === 7 ? `${OLD_ID_PREFIX}${id}` : id;

    addEventView({
      variables: { id: generateId(id as string), type: type },
      onCompleted: (data) => {},
      onError: (error) => {},
    });
  };

  const addBannerViewCount = (id: string, type: string) => {
    addBannerView({
      variables: { id: generateId(id as string), type: type },
      onCompleted: (data) => {},
      onError: (error) => {},
    });
  };

  const addPostViewCount = (id: string, type: string) => {
    addPostView({
      variables: { id: generateId(id as string), type: type },
      onCompleted: (data) => {},
      onError: (error) => {},
    });
  };

  return {
    ...defaultValue,
    district: getDistrictsData?.Districts?.docs?.[0] as ModelTypes["District"],
    setting: getCriteriaSettingsData?.GetCriteriaSettings
      ?.docs?.[0] as ModelTypes["CriteriaSetting"],
    mobileDrawerDisclosure,
    mobileSearchDrawerDisclosure,
    mobilePostDrawerDisclosure,
    isMobile,
    isDesktop,
    APP_INSETS,
    DESKTOP_HEADER_HEIGHT,
    MOBILE_HEADER_HEIGHT,
    PUSH_TOKEN: pushToken,
    UDID: deviceUdid,
    apolloClient,
    addEventViewCount,
    addBannerViewCount,
    addPostViewCount,
  };
};